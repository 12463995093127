<template>
<v-main app>
    <template v-if="!isAuthenticated">
      <authorise-xero />
    </template>
    <template v-if="isAuthenticated">
      <v-navigation-drawer
        clipped
        app
        fixed
        :permanent="$vuetify.breakpoint.mdAndUp"
        style="z-index: 1"
      >
        <v-list>
          <v-list-item>
            <v-btn class="d-flex justify-start" block outlined color="primary" to="/admin/xero/customers">
              <v-icon class="mr-4">
                mdi-account-multiple
              </v-icon>
              Contacts
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn class="d-flex justify-start" block outlined color="primary" to="/admin/xero/registrations">
              <v-icon class="mr-4">
                mdi-account-plus
              </v-icon>
              Registrations
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn class="d-flex justify-start" block outlined color="primary" to="/admin/xero/invoices">
              <v-icon class="mr-4">
                mdi-point-of-sale
              </v-icon>
            Invoices
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn class="d-flex justify-start" block outlined color="primary" to="/admin/xero/products">
              <v-icon class="mr-4">
                mdi-coffee
              </v-icon>
            Products / Items
            </v-btn>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-container fluid>
        <router-view />
      </v-container>
    </template>
</v-main>
</template>
<script>
import AccountingApi from '@/api/accounting'
import AuthoriseXero from '@/views/Admin/Xero/AuthoriseXero.vue'
export default {
  props: {
  },
  components: {
    AuthoriseXero
  },
  data () {
    return {
      isAuthenticated: false
    }
  },
  async created () {
    AccountingApi.hasXeroConnection().then(response => { this.isAuthenticated = response.data })
  },
  computed: {
  },
  watch: {},
  methods: {
  }
}
</script>
<style lang="scss" scoped>
  // .sidebar {
  //   margin-top: 112px !important;
  // }
</style>
